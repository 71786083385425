import { navigate, useLocation } from "@reach/router";
import queryString from "query-string";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { BiSearch } from "react-icons/bi";

export function Search({ visible }) {
	const [searchTerm, setSearchTerm] = React.useState("");

	const { search } = useLocation();
	const c = queryString.parse(search)?.c || "";

	const handleSubmit = (e) => {
		e.preventDefault();

		if (c) {
			navigate(
				`/support-center/?s=${encodeURIComponent(
					searchTerm
				)}&c=${encodeURIComponent(c)}`
			);
		} else {
			navigate(`/support-center/?s=${encodeURIComponent(searchTerm)}`);
		}
	};

	return (
		<Container className={`pb-2 `}>
			<Row
				style={{
					borderRadius: "11px",
				}}
				className="align-items-center justify-content-between bg-light-grey "
			>
				<form
					style={{
						width: "100%",
					}}
					onSubmit={handleSubmit}
					className="d-flex search-bar "
				>
					<Col className="pr-0 mr-0" xs={9} md={11}>
						<input
							style={{
								height: "5rem",
								borderRadius: "200px",
							}}
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.currentTarget.value)}
							placeholder="Search..."
							className="pb-1 ps-4  mx-0 w-100 search-bar"
						/>
					</Col>
					<Col
						className="d-flex justify-content-end align-items-center "
						xs={3}
						md={1}
					>
						<Button
							variant="white"
							style={{}}
							className=" black-link "
							type="submit"
						>
							<BiSearch
								className=""
								style={{ height: "1.5rem", width: "1.5rem" }}
							/>
						</Button>
					</Col>
				</form>
			</Row>
		</Container>
	);
}
